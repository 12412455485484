// Import the functions you need from the SDKs
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBfKgyAmscr07dl1dXXyV8ettxvCKTlupE",
    authDomain: "todoapp-eeeb7.firebaseapp.com",
    projectId: "es932uwb",
    storageBucket: "todoapp-eeeb7.appspot.com",
    messagingSenderId: "1072574112522",
    appId: "1:1072574112522:web:65fc4e184aed9894dc90f3"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and Realtime Database
export const auth = getAuth(app);
export const database = getDatabase(app);

export default app;
