import React, { useState, useEffect, useRef } from 'react';
import '../Pages/HistoryView.css';
import { onSnapshot, getDatabase, ref, onValue } from 'firebase/database';
import app from '../firebase';
import SyncLoader from "react-spinners/SyncLoader";

const HistoryView = ({ selectedTag }) => {

    const [selectedTagData, setSelectedTagData] = useState([]);
    const [loading, setLoading] = useState(false);
    function formatDate(day, month, hour, minutes) {
        // Create a date object with the provided values
        const date = new Date();

        // Set the desired values
        date.setDate(day);
        date.setMonth(month - 1); // Months are 0-indexed in JavaScript
        date.setHours(hour);
        date.setMinutes(minutes);

        // Format the date to the desired string format
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false // Use 24-hour format
        };

        return date.toLocaleString('en-US', options);
    }

    useEffect(() => {
        if (selectedTag) {
            setLoading(true);
            const db = getDatabase(app);
            const tagRef = ref(db, `/${selectedTag}`); // Referring to the 'tag0' document

            const unsubscribe = onValue(tagRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    const dataList = Object.values(data);
                    console.log("Data List:", dataList);
                    // Convert the object inside 'tag0' into a list of objects

                    // console.log("selectedTagDataList" + dataList);
                    // console.log("selectedTagDataList" + selectedTag);
                    if (dataList == null) {
                        setSelectedTagData([]);
                    } else {
                        setSelectedTagData(dataList.reverse());
                    }

                    setLoading(false);
                } else {
                    setLoading(false);
                }
            });

            return () => {
                unsubscribe(); // Clean up the listener
            };
        }

    }, [selectedTag]);

    function getFirstDigit(num) {
        // Convert the number to a string
        const numStr = num.toString();

        // Return the first character (digit) as a number
        return parseInt(numStr.charAt(0), 10);
    }


    return (
        <div className='history-view-body'>



            {loading ? <div className="loader-container">
                <SyncLoader color="#36D7B7" />
            </div> : <div>
                {(selectedTag == null||selectedTag=="") ? <p className='error-title'>Please select a Tag</p> : selectedTagData.map((item, index) => (item.date == null) ? <></> : (
                    <div key={index} className='history-detail-card'>
                        <p>Distance: {item.distance} m</p>
                        <p>Date: {formatDate(item.date, item.month, item.h, item.m)}</p>

                        <p>Room: {getFirstDigit(item.room)}</p>
                    </div>
                ),)}
            </div>}
        </div>
    )
};

export default HistoryView;

