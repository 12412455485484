import React, { useState, useEffect, useRef } from 'react';
import '../Pages/HomePage.css';
import { onSnapshot, getDatabase, ref, onValue } from 'firebase/database';
import app from '../firebase';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import HistoryView from './HistoryView';
import FindView from './FindView';
import RingLoader from "react-spinners/RingLoader";
import SearchField from '../Components/SearchField';
const HomePage = () => {
    const [tags, setTags] = useState([]);
    const constTags = useRef([])
    const [selectedTag, setSelectedTag] = useState('');
    const [selectedTagData, setSelectedTagData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        changeLoaderStatus(true);
        const db = getDatabase(app);
        const rootRef = ref(db, '/'); // Referring to the root of the database

        // Fetch data from the root

        const unsubscribe = onValue(rootRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                // Filter out the keys that start with 'tag'
                // const filteredTags = Object.entries(data)
                //   .filter(([key, _value]) => key.startsWith('tag')) // Filter keys that start with 'tag'
                //   .reduce((acc, [key, value]) => {
                //     acc[key] = value; // Add the matching key-value pairs to the result
                //     return acc;
                //   }, {});
                const tagKeys = Object.keys(data);
                console.log(tagKeys);
                if (tagKeys == null) {
                    setTags([])
                } else {
                    setTags(tagKeys);
                    constTags.current = tagKeys;
                }

                changeLoaderStatus(false);
            } else {
                changeLoaderStatus(false);
            }
        },);
        // changeLoaderStatus(false);
        return () => {
            unsubscribe(); // Clean up the listener
        };
    }, []);



    const changeLoaderStatus = (loading) => {
        setLoading(loading);  // Update the state with the selected relay
    };

    function getTagDisplayName(tag) {
        let tagDisplayName = 'Tag';
        const numberPart = parseInt(tag.replace('tag', ''), 10);

        if (tag == null) {
            return tagDisplayName;
        }

        if (isNaN(numberPart)) {
            return tagDisplayName; // return null if it's not a valid number
        }

        // Increment the number part by 1
        return `${tagDisplayName} ${numberPart + 1}`;
    }

    const onSearchChange = (text) => {
        if (text && text.trim() !== "") {
            // Filter tags that match the search text
            const matchingTags = constTags.current.filter(tag =>
                (getTagDisplayName(tag).toLowerCase().includes(text.toLowerCase())) || ((getTagDisplayName(tag).toLowerCase().replace(/\s+/g, '').includes(text.toLowerCase().replace(/\s+/g, ''))))
            );
            setTags(matchingTags);
        } else {
            // If the text is empty or null, reset the tags
            setTags(constTags.current);
        }
    }


    return (
        <div className='home-page'>
            {loading && (
                <div className="loader-overlay">
                    <RingLoader loading={loading} color="white" />
                </div>
            )}
            <div className='content'>
                <div className='tag-container'>
                    <SearchField onSearchChange={onSearchChange} />
                    
                    {(tags.length <= 0) ? <p className='no-tags-text'>There are no tags</p> :
                        tags.map((tag) => (
                            <div className={selectedTag == tag ? 'tag tag-select' : 'tag'} onClick={() => setSelectedTag(tag)}>   <h1>{getTagDisplayName(tag)}</h1></div>

                        ))
                    }


                </div>
                <div className='tab-container'>
                    <Tabs colorScheme="teal tab-space" variant="soft-rounded">
                        <TabList className='tab-header-container'>
                            <Tab className='tab-header'>History</Tab>
                            <Tab className='tab-header'>Find</Tab>
                        </TabList>

                        <TabPanel>
                            <HistoryView selectedTag={selectedTag} />
                        </TabPanel>
                        <TabPanel>
                            <FindView selectedTag={selectedTag} />
                        </TabPanel>
                    </Tabs>
                </div>
            </div>

        </div>
    );

}

export default HomePage;