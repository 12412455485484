import React, { useState, useEffect, useRef } from 'react';
import '../Pages/LoginPage.css'
import EmailInputField from '../Components/EmailInputField';
import PasswordField from '../Components/PasswordField';
import Button from '../Components/Button';

import { NavLink, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword,signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';


const LoginPage = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

    // const onSubmit = async (e) => {
    //     e.preventDefault()

    //     await createUserWithEmailAndPassword(auth, email, password)
    //         .then((userCredential) => {
    //             // Signed in
    //             const user = userCredential.user;
    //             console.log(user);
    //             navigate("/login")
    //             // ...
    //         })
    //         .catch((error) => {
    //             const errorCode = error.code;
    //             const errorMessage = error.message;
    //             console.log(errorCode, errorMessage);
    //             // ..
    //         });


    // }

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            navigate("/home")
            console.log(user);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });

    }


    return (
        <div id='login-page-body'>
            <div className='content'>
                <div className='side-container'>
                    <h1 id='login-page-tilte'>
                    UWB Tracking<span class="item-title">System</span>
                    </h1>
                </div>
                <div className='side-container'>
                    <div id='login-container'>
                        <h1>Login</h1>
                        <EmailInputField onEmailChange={setEmail} />
                        <PasswordField onPasswordChange={setPassword} />

                        <Button onButtonClick={onLogin}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
