import React, { useState, useEffect, useRef } from 'react';
import '../Components/InputField.css';
import { FaSearch } from 'react-icons/fa';


const SearchField = ({ onSearchChange }) => {
    return (
        <div className='padding'>
            <div class="container">
                <input required="" type="text" name="text" class="input" onChange={(e) => onSearchChange(e.target.value)} />
                <label class="label">Search</label>

            </div>
        </div>


    );
}

export default SearchField;