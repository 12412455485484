import React, { useState, useEffect, useRef } from 'react';
import '../Components/Button.css'

const Button=({ onButtonClick })=>{
    return(<button class="button-72" role="button" onClick={onButtonClick}>Login</button>);
 }


 export default Button;


