import '../Pages/FindView.css';
import React, { useState, useEffect, useRef } from 'react';

import { onSnapshot, getDatabase, ref, onValue, update } from 'firebase/database';
import app from '../firebase';
import SyncLoader from "react-spinners/SyncLoader";

const FindView = ({ selectedTag }) => {
    const [findData, setFindData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (selectedTag) {
            setLoading(true);
            toggleSearchValue(selectedTag, 1);
            const db = getDatabase(app);
            const tagRef = ref(db, `/${selectedTag}/find`); // Referring to the 'tag0' document
            console.log("selectedTasssgDataList" + `/${selectedTag}/find`);
            const unsubscribe = onValue(tagRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {

                    // Convert the object inside 'tag0' into a list of objects

                    // console.log("selectedTagDataList" + dataList);

                    setFindData(data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }, (error) => {
                // setLoading(false);
                console.error("Error fetching find data:", error);
            });

            return () => {
                unsubscribe(); // Clean up the listener
                toggleSearchValue(selectedTag, 0);
            };
        }

    }, [selectedTag]);

    const toggleSearchValue = (tagName, value) => {
        const db = getDatabase(app);
        const tagRef = ref(db, `/${tagName}`);

        // Fetch the current value and toggle between 0 and 1
        update(tagRef, { search: value }).then(() => {
            console.log('Search value updated to 1.');
        }).catch((error) => {
            console.error('Error updating search value:', error);
        });
    };

    function getFirstDigit(num) {
        // Convert the number to a string
        const numStr = num.toString();

        // Return the first character (digit) as a number
        return parseInt(numStr.charAt(0), 10);
    }

    function getTagDisplayName(tag) {
        let tagDisplayName = 'Tag';
        const numberPart = parseInt(tag.replace('tag', ''), 10);

        if (tag == null) {
            return tagDisplayName;
        }

        if (isNaN(numberPart)) {
            return tagDisplayName; // return null if it's not a valid number
        }

        // Increment the number part by 1
        return `${tagDisplayName} ${numberPart + 1}`;
    }



    return (
        <div className="find-view-container">
            {loading ? <div className="loader-container">
                <SyncLoader color="#36D7B7" />
            </div> : (selectedTag == null || selectedTag == "") ? <p className='find-view-error-title'>Please select a Tag</p> :
                (findData.distance == null || findData.room == null) ?
                    <p>Error while retreving data</p> : <>
                        <h1 className='h1-tag'> {getTagDisplayName(selectedTag)} is in Room {getFirstDigit(findData.room)}</h1>
                        <h1 className='h1-tag'>away {findData.distance}m from anchor {getFirstDigit(findData.room)}</h1>
                    </>
            }


        </div>
    );
};

export default FindView;