import React, { useState, useEffect, useRef } from 'react';
import '../Components/InputField.css'


const EmailInputField = ({ onEmailChange }) => {
    return (
        
            <div class="container">
                <input required="" type="email" name="email" class="input" onChange={(e) => onEmailChange(e.target.value)} />
                <label class="label">Email</label>
            </div>
        
    );
}

export default EmailInputField;